import { useMemo, useState } from 'react';
import { PlayArrowOutlined, Settings } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { DataGrid, GridColumns } from '@mui/x-data-grid';
import { CrudFilters, getDefaultFilter, useInvalidate } from '@refinedev/core';
import { List, useDataGrid, DateField, EditButton, DeleteButton } from '@refinedev/mui';
import { useForm } from '@refinedev/react-hook-form';
import { Controller } from 'react-hook-form';

import { BinaryNameDialog } from './components/binary_name_dialog';
import { buildAllPackages, buildManyPackages, buildPackage } from './components/build_package';
import { Status } from './components/constants';

const FlexPackagesList = () => {
  const invalidate = useInvalidate();
  const [isBinaryNameDialogOpen, setIsBinaryNameDialogOpen] = useState(false);
  const [isRebuildAllDialogOpen, setIsRebuildAllDialogOpen] = useState(false);
  const [idsSelected, setIdsSelected] = useState([]);
  const [binaryName, setBinaryName] = useState('');
  const [currentId, setCurrentId] = useState('');

  const invalidateListQuery = () => {
    invalidate({
      resource: 'flex_packages',
      invalidates: ['list']
    });
  };

  const columns = useMemo<GridColumns>(() => {
    return [
      {
        field: 'package_image',
        headerName: 'Image',
        sortable: false,
        renderCell: params => {
          return (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '32px',
                height: '32px'
              }}
              component="picture"
            >
              <img
                style={{
                  width: '100%',
                  height: '100%'
                }}
                onError={e => {
                  e.target.remove();
                }}
                src={params.row.package_image}
                alt={`${params.row.name}image`}
              />
            </Box>
          );
        }
      },
      {
        field: 'name',
        headerName: 'Flex Package Name',
        width: 200,
        flex: 2,
        renderCell: params => {
          return (
            <Tooltip title={params.row.name}>
              <Typography variant="body1" noWrap>
                {params.row.name}
              </Typography>
            </Tooltip>
          );
        }
      },
      {
        field: 'package_type',
        headerName: 'Package Type',
        flex: 2,
        renderCell: params => {
          return (
            <Tooltip title={params.row.package_type_name}>
              <Typography variant="body1" noWrap>
                {params.row.package_type_name}
              </Typography>
            </Tooltip>
          );
        }
      },
      {
        field: 'version',
        headerName: 'Version',
        flex: 1,
        renderCell: params => {
          return (
            <Tooltip title={params.row.version}>
              <Typography variant="body1" noWrap>
                {params.row.version}
              </Typography>
            </Tooltip>
          );
        }
      },
      {
        field: 'executor_version',
        headerName: 'Executor version',
        flex: 1,
        renderCell: params => {
          return (
            <Tooltip title={params.row.executor_version}>
              <Typography variant="body1" noWrap>
                {params.row.executor_version}
              </Typography>
            </Tooltip>
          );
        }
      },
      {
        field: 'project_template',
        headerName: 'Project Template',
        flex: 2,
        renderCell: params => {
          return (
            <Tooltip title={params.row.project_template}>
              <Typography variant="body1" noWrap>
                {params.row.project_template}
              </Typography>
            </Tooltip>
          );
        },
        sortable: false
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        renderCell: params => {
          return (
            <Tooltip title={params.row.status}>
              <Typography variant="body1" noWrap>
                {params.row.status}
              </Typography>
            </Tooltip>
          );
        }
      },
      {
        field: 'created',
        headerName: 'Created At',
        renderCell: ({ value }) => <DateField format="LLL" value={value} />,
        flex: 1,
        sortable: false
      },
      {
        field: 'modified',
        headerName: 'Updated At',
        renderCell: ({ value }) => <DateField format="LLL" value={value} />,
        flex: 1,
        sortable: false
      },
      {
        field: 'actions',
        headerName: 'Actions',
        minWidth: 250,
        sortable: false,
        renderCell: params => {
          return (
            <Stack direction="row" spacing={1}>
              <EditButton hideText recordItemId={params.row.id} />
              <Button
                sx={{ minWidth: 0 }}
                startIcon={<Settings sx={{ selfAlign: 'center' }} />}
                title="Recreate Package with binary name settings"
                disabled={params.row.status === Status.IN_PROGRESS}
                onClick={() => {
                  setBinaryName(params.row.binary_name);
                  setCurrentId(params.row.id);
                  setIsBinaryNameDialogOpen(true);
                }}
              />
              <Button
                sx={{ minWidth: 0 }}
                startIcon={<PlayArrowOutlined sx={{ selfAlign: 'center' }} />}
                title="Recreate Package"
                disabled={params.row.status === Status.IN_PROGRESS}
                onClick={() => {
                  buildPackage({
                    id: params.row.id,
                    binaryName: params.row.binary_name,
                    onFinish: invalidateListQuery
                  });
                }}
              />
              <DeleteButton hideText recordItemId={params.row.id} />
            </Stack>
          );
        }
      }
    ];
  }, [invalidate]);

  const handleCloseBinaryNameDialog = async (success: boolean, binary_name: string) => {
    setIsBinaryNameDialogOpen(false);
    if (success) {
      buildPackage({ id: currentId, binaryName: binary_name, onFinish: invalidateListQuery });
    }
  };

  const rebuildSelected = async () => {
    buildManyPackages({ ids: idsSelected, onFinish: invalidateListQuery });
    setIdsSelected([]);
  };

  const rebuildAll = async () => {
    setIsRebuildAllDialogOpen(false);
    buildAllPackages({ onFinish: invalidateListQuery });
  };

  const { dataGridProps, filters, search } = useDataGrid({
    onSearch: params => {
      const filters: CrudFilters = [];
      const { search } = params;

      filters.push({
        field: 'search',
        operator: 'eq',
        value: search
      });

      return filters;
    },

    filters: {
      initial: [
        {
          field: 'search',
          operator: 'eq',
          value: ''
        }
      ]
    }
  });

  const { control, handleSubmit } = useForm({
    defaultValues: {
      search: getDefaultFilter('search', filters, 'eq') || ''
    }
  });

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={3}>
          <Card>
            <CardHeader title="Filters" />
            <CardContent>
              <Box
                component="form"
                autoComplete="off"
                sx={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
                onSubmit={handleSubmit(search)}
              >
                <Controller
                  name="search"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      value={value || ''}
                      onChange={onChange}
                      label="Flex Package"
                      placeholder="Search by name"
                      margin="normal"
                      fullWidth
                      autoFocus
                    />
                  )}
                />

                <br />
                <Button type="submit" variant="contained">
                  Apply
                </Button>
              </Box>
            </CardContent>
          </Card>
          <br />
          <Card>
            <CardHeader title="Build options" />
            <CardContent>
              <Button onClick={rebuildSelected} disabled={idsSelected.length == 0} variant="contained">
                Rebuild Selected
              </Button>
              <br />
              <br />
              <Button onClick={rebuildSelected} disabled={true} variant="contained">
                Rebuild Type (coming soon)
              </Button>
              <br />
              <br />
              <Button
                onClick={() => {
                  setIsRebuildAllDialogOpen(true);
                }}
                variant="contained"
              >
                Rebuild All
              </Button>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={9}>
          <List canCreate>
            <DataGrid
              {...dataGridProps}
              checkboxSelection
              disableRowSelectionOnClick
              keepNonExistentRowsSelected
              filterModel={undefined}
              autoHeight
              columns={columns}
              disableColumnMenu
              selectionModel={idsSelected}
              onSelectionModelChange={ids => {
                setIdsSelected(ids);
              }}
            />
          </List>
        </Grid>
      </Grid>
      <BinaryNameDialog open={isBinaryNameDialogOpen} onClose={handleCloseBinaryNameDialog} binaryName={binaryName} />
      <Dialog
        open={isRebuildAllDialogOpen}
        onClose={() => {
          setIsRebuildAllDialogOpen(false);
        }}
        fullWidth
      >
        <DialogTitle>Rebuild All</DialogTitle>
        <DialogContent>Are you sure?</DialogContent>
        <DialogActions>
          <Button onClick={rebuildAll}>Start Building</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FlexPackagesList;
