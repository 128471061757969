import { useEffect, useState } from 'react';
import { ExpandMore as ExpandMoreIcon, ImageOutlined } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Link,
  Paper,
  Skeleton,
  Typography
} from '@mui/material';
import { useNavigation, useNotification } from '@refinedev/core';
import { useForm } from '@refinedev/react-hook-form';
import { AxiosError } from 'axios';
import { Controller, FormProvider } from 'react-hook-form';

import { AiRemirror, Dropzone } from '@attackiq/components';

import { NoRefreshEdit } from '../../components';
import { uploadFile } from '../../utils/uploadFile';

import { BinaryNameDialog } from './components/binary_name_dialog';
import { buildPackage } from './components/build_package';
import { Status } from './components/constants';
import {
  CreditsInput,
  FeaturedCheckbox,
  NameInput,
  PackageTypeInput,
  PurposeInput,
  VersionInput
} from './components/input_components';

const FlexPackagesEdit = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | undefined | null>(null);
  const [buildStatus, setBuildStatus] = useState(Status.IN_PROGRESS);
  const { saveButtonProps, ...formMethods } = useForm({
    refineCoreProps: {
      action: 'edit',
      queryOptions: {
        staleTime: 0,
        cacheTime: 0,
        structuralSharing: false
      }
    }
  });
  const {
    refineCore: { queryResult, formLoading, onFinish, id },
    control,
    handleSubmit
  } = formMethods;

  const { editUrl } = useNavigation();
  const { open } = useNotification();

  const onSubmit = handleSubmit(async ({ name, description, package_type, version, credits, featured, purpose }) => {
    if (selectedFile) {
      try {
        await uploadFile(selectedFile, `/v2/flex_packages/${id}/upload_package_image`, 'image', 'put');
      } catch (error) {
        open?.({
          type: 'error',
          message: (error as AxiosError).response?.data?.detail
        });
      }
    }
    onFinish({
      name: name,
      description: description ? description : '',
      package_type: package_type.id,
      version: version,
      credits: credits,
      featured: featured,
      purpose: purpose
    });
  });

  useEffect(() => {
    if (queryResult) {
      setBuildStatus(queryResult?.data?.data.status);
    }
  }, [queryResult]);

  const handleBuildPackage = async () => {
    if (buildStatus !== Status.IN_PROGRESS) {
      setIsDialogOpen(true);
    }
  };

  const handleCloseDialog = async (success: boolean, binary_name: string) => {
    setIsDialogOpen(false);
    if (success) {
      buildPackage({
        id: id,
        binaryName: binary_name,
        onFinish: () => {
          queryResult?.refetch();
        },
        onError: e => {
          open?.({ type: 'error', message: 'Error calling create_package_file' });
        }
      });
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2
        }}
      >
        <Paper variant="outlined">
          <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }}>
            <Box>
              <Typography variant="subtitle2">Flex Package ID: {queryResult?.data?.data.id}</Typography>
              <Typography variant="subtitle2">
                Assessment Template:{' '}
                <Link href={editUrl('assessment_templates', queryResult?.data?.data.project_template)}>
                  {queryResult?.data?.data.project_template}
                </Link>
                <br />
              </Typography>
              <Typography variant="subtitle2">Status: {buildStatus}</Typography>
              <Typography variant="subtitle2">
                File: <Link href={queryResult?.data?.data.package_file}>Link</Link>
              </Typography>
            </Box>
            <Box>
              <Button
                variant="contained"
                disabled={buildStatus === Status.IN_PROGRESS}
                onClick={() => handleBuildPackage()}
              >
                Rebuild this package {buildStatus === Status.IN_PROGRESS ? '(Refresh for updates)' : ''}
              </Button>
            </Box>
          </Box>
        </Paper>
        <FormProvider {...formMethods}>
          <form name="create-flex-packages-form" onSubmit={onSubmit}>
            <NoRefreshEdit canDelete isLoading={formLoading} saveButtonProps={{ type: 'submit' }}>
              {formLoading ? (
                <Skeleton variant="rectangular" height={500} width="100%" />
              ) : (
                <>
                  <NameInput required={false} />
                  <br />

                  <Accordion defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>Flex Package description*</AccordionSummary>
                    <AccordionDetails>
                      <Controller
                        control={control}
                        name="description"
                        rules={{ required: 'Flex Package description is required' }}
                        render={({ field: { onChange, value } }) => {
                          if (value === undefined) {
                            return <>Loading...</>;
                          }
                          return <AiRemirror value={value || ''} onChange={onChange} />;
                        }}
                      />
                    </AccordionDetails>
                  </Accordion>

                  <br />
                  <PackageTypeInput isLoading={formLoading} val={queryResult?.data?.data.package_type} />
                  <VersionInput />
                  <CreditsInput />
                  <FeaturedCheckbox isLoading={formLoading} val={queryResult?.data?.data.featured} control={control} />
                  <PurposeInput />
                  <Dropzone
                    rules={{
                      accept: 'image/*'
                    }}
                    onDropAccepted={files => {
                      setSelectedFile(files[0]);
                    }}
                    width="100%"
                    height={152}
                  >
                    <Dropzone.Body>
                      <>
                        <Typography>
                          Package Image: <Link component="span">Click to upload</Link> or drag and drop
                        </Typography>
                        <Typography color="text.secondary">Images supported only</Typography>
                      </>
                    </Dropzone.Body>

                    <Dropzone.Cover
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      flexDirection="column"
                      bgcolor="background.paper"
                      in={!!selectedFile}
                    >
                      <ImageOutlined color="primary" />
                      <Typography>{selectedFile?.name}</Typography>
                    </Dropzone.Cover>
                  </Dropzone>
                </>
              )}
            </NoRefreshEdit>
          </form>
        </FormProvider>
      </Box>
      <BinaryNameDialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        binaryName={queryResult?.data?.data.binary_name}
      />
    </>
  );
};

export default FlexPackagesEdit;
