import { BaseKey } from '@refinedev/core';

import httpClient from '../../../utils/axios';

export const buildPackage = async ({
  id,
  binaryName,
  onFinish,
  onError
}: {
  id: BaseKey;
  binaryName?: string;
  onFinish?: () => void;
  onError?: (e) => void;
}) => {
  try {
    if (binaryName) {
      const formData = new FormData();
      formData.append('binary_name', binaryName);
      await httpClient.post(`/v2/flex_packages/${id}/create_package_file`, formData);
    } else {
      await httpClient.post(`/v2/flex_packages/${id}/create_package_file`);
    }
  } catch (e) {
    onError?.(e);
  } finally {
    onFinish?.();
  }
};

export const buildManyPackages = async ({
  ids,
  onFinish,
  onError
}: {
  ids: BaseKey[];
  onFinish?: () => void;
  onError?: (e) => void;
}) => {
  try {
    const formData = new FormData();
    formData.append('many', ids.toString());
    await httpClient.post(`/v2/flex_packages/recreate_several`, formData);
  } catch (e) {
    onError?.(e);
  } finally {
    onFinish?.();
  }
};

export const buildAllPackages = async ({ onFinish, onError }: { onFinish?: () => void; onError?: (e) => void }) => {
  try {
    const formData = new FormData();
    formData.append('all', 'true');
    await httpClient.post(`/v2/flex_packages/recreate_several`, formData);
  } catch (e) {
    onError?.(e);
  } finally {
    onFinish?.();
  }
};
