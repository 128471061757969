import { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';

export const BinaryNameDialog = ({
  open,
  onClose,
  binaryName
}: {
  open: boolean;
  onClose: (b: boolean, s: string) => void;
  binaryName: string;
}) => {
  const [inputBinaryName, setInputBinaryName] = useState('');

  useEffect(() => {
    setInputBinaryName(binaryName);
  }, [binaryName]);

  const closeDialog = () => {
    onClose(false, '');
  };

  const handleSet = () => {
    if (inputBinaryName) {
      onClose(true, inputBinaryName);
    } else {
      onClose(true, binaryName);
    }
  };

  return (
    <Dialog open={open} onClose={closeDialog} fullWidth>
      <DialogTitle>Select Binary Name</DialogTitle>
      <DialogContent>
        <TextField
          label="Binary Name (leave empty for default)"
          placeholder="Leave empty for default"
          onChange={event => setInputBinaryName(event.target.value)}
          sx={{ mt: 0.5 }}
          value={inputBinaryName}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSet}>Start Building</Button>
      </DialogActions>
    </Dialog>
  );
};
